import React from 'react';
import {
  BoxContainer,
  Boxes,
  BoxHeader,
  Boximage,
  Boxtext,
  div,
} from './ServicesStyle';
import UIUX from '../../../images/ux.png';
import Graphics from '../../../images/design.png';
import Webdev from '../../../images/web-design.png';
import Appdev from '../../../images/mobile-development.png';
import Cloud from '../../../images/cloud.png';
import Digital from '../../../images/digital-marketing.png';

const ServicesSection = () => {
  return (
    <div>
      <div className='w-full py-[5%] bg-black relative flex justify-center '>
        <div className='md:w-4/5 w-[90%]  '>
          <div className='w-full flex items-center flex-col gap-4 mb-12'>
            <h1 className='text-white text-[clamp(16px,3vw,50px)] font-bold'>
              Our Services
            </h1>
            <p className='text-white text-[clamp(13px,2vw,30px) flex justify-center]'>
              What We Do
            </p>
          </div>

          <BoxContainer>
            <Boxes>
              <Boximage>
                <img
                  src={UIUX}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                    color: 'rgb(198,170,40)',
                  }}
                />
              </Boximage>
              <BoxHeader>UI/UX Design</BoxHeader>
              <Boxtext>
                We provide comprehensive graphic design solutions that include
                logo design, branding, packaging, flyers, posters, social media
                graphics, and more
              </Boxtext>
            </Boxes>

            <Boxes>
              <Boximage>
                <img
                  src={Graphics}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                    color: 'rgb(198,170,40)',
                  }}
                />
              </Boximage>
              <BoxHeader>Graphic Design</BoxHeader>
              <Boxtext>
                We provide comprehensive graphic design solutions that include
                logo design, branding, packaging, brochures, flyers, posters,
                social media graphics, and more.{' '}
              </Boxtext>
            </Boxes>

            <Boxes>
              <Boximage>
                <img
                  src={Webdev}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                    color: 'rgb(198,170,40)',
                  }}
                />
              </Boximage>
              <BoxHeader>Website Design</BoxHeader>
              <Boxtext>
                Our team of experienced designers and developers work together
                to create custom designs that align with your brand and business
                goals.
              </Boxtext>
            </Boxes>

            <Boxes>
              <Boximage>
                <img
                  src={Appdev}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                    color: 'rgb(198,170,40)',
                  }}
                />
              </Boximage>
              <BoxHeader>App Development</BoxHeader>
              <Boxtext>
                {' '}
                Our team of experienced developers specializes in building
                custom mobile and web applications that cater to your unique
                business needs.
              </Boxtext>
            </Boxes>

            <Boxes>
              <Boximage>
                <img
                  src={Cloud}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                    color: 'rgb(198,170,40)',
                  }}
                />
              </Boximage>
              <BoxHeader>Cloud Solutions</BoxHeader>
              <Boxtext>
                Are you tired of dealing with the hassle and expense of managing
                your own IT infrastructure? Look no further than our cloud
                solution services!
              </Boxtext>
            </Boxes>

            <Boxes>
              <Boximage>
                <img
                  src={Digital}
                  alt=''
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                    color: 'rgb(198,170,40)',
                  }}
                />
              </Boximage>
              <BoxHeader>Digital Marketing</BoxHeader>
              <Boxtext>
                We specialize in creating customized strategies that maximize
                your brand's visibility across various platforms.
              </Boxtext>
            </Boxes>
          </BoxContainer>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;

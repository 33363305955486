import React from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className='w-full top-0 relative aspect-[2.0/1] min-h-[450px]'>
      <div className='w-full h-full absolute flex items-center '>
        <div className='w-full h-full  '>
          <video
            src='https://lukeshubstorage.blob.core.windows.net/videos/precision-herobg.mp4'
            autoPlay
            loop
            muted
            typeof='video/mp4'
            width='100%'
            height='100%'
            style={{ objectFit: 'fill' }}
            className='w-full h-full  '
          />
        </div>

        <div className='absolute h-full  flex flex-col justify-center items-center gap-8  '>
          <div className='w-4/5 md:w-3/5 text-white font-bold text-[clamp(14px,3vw,50px)]  flex justify-center  text-center '>
            Choose What Matters To Your Business & Your Customers.
          </div>
          <div className=' text-white w-4/5 md:w-3/5 text-[clamp(12px,2vw,20px)] text-center'>
            We take great pride in building solutions that perform well today,
            and are built with an ongoing strategy of seamlessly working to meet
            the needs of your business.
          </div>
          <Link to='/contact' className='w-full flex justify-center'>
            <button className='bg-[#e0aa22] w-[clamp(130px,20%,220px)] text-bold text-[clamp(12px,2vw,20px)] rounded-sm cursor-pointer aspect-[4/1]'>
              Contact us now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

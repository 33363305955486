import React from 'react';
import './project.css';
import Yourrider from '../../../images/yourrider.PNG';
import Nasacrown from '../../../images/nasa.PNG';
import Precision from '../../../images/precisioninfo.PNG';
import The3kshop from '../../../images/the3kshop.PNG';
import Nearpays from '../../../images/nearpays-snip.PNG';
import Teleconnect from '../../../images/teleconnect.PNG';

const data = [
  {
    id: 1,
    image: Nearpays,
    title: 'Nearpays Limited',
    github: 'https://github.com/lukeola/nearpays',
    demo: 'https://nearpays.com',
  },

  {
    id: 2,
    image: Yourrider,
    title: 'Your Rider',
    github: 'https://github.com/lukeola/yourrider',
    demo: 'https://yourrider.com',
  },

  {
    id: 3,
    image: Precision,
    title: 'Precision Infotech',
    github: 'https://github.com/lukeola/precisioninfotech',
    demo: 'https://Precisioninfotech.com',
  },

  {
    id: 4,
    image: The3kshop,
    title: 'The 3K Shop',
    github: 'https://github.com/lukeola/the3kshop',
    demo: 'https://the3kshop.com',
  },

  {
    id: 5,
    image: Teleconnect,
    title: 'Teleconnect Outsource',
    github: 'https://github.com/lukeola/teleconnect',
    demo: 'https://teleconnectoutsource.com',
  },

  {
    id: 6,
    image: Nasacrown,
    title: 'Nasacrown',
    github: 'https://github.com/lukeola/nasacrown',
    demo: 'https://nasacrown.com.ng',
  },
];

const Project = () => {
  return (
    <div className='portfolio__div'>
      <section className='w-full'>
        <h5 className='headertext'>
          <div className='headertextline' />
          <p
            style={{
              color: 'rgb(198,170,40) ',
              fontWeight: '400',
              fontSize: '18px',
            }}
          >
            Our Case Study
          </p>
          <div className='headertextline' />
        </h5>
        <h2 className='projectheader'>Our Recent Project</h2>

        <div className='container portfolio__container'>
          {data.map(({ id, image, title, github, demo }) => {
            return (
              <article className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img
                    src={image}
                    alt={title}
                    style={{
                      objectFit: 'contain',
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                  <a
                    href={demo}
                    target='blank'
                    className='projectbtn'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    Visit Website
                  </a>
                </div>
              </article>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Project;

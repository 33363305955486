// src/App.js
import React, { useState } from 'react';
import './../../tailwind.css';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Toaster, toast } from 'react-hot-toast';

const ContactUs = () => {
  const [setStatus] = useState('');
  const { register, handleSubmit, formState, reset } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async (e) => {
    console.log(e);
    try {
      const response = await axios.post(
        'https://api.precisioninfotech.com/contact',
        e
      );

      if (response.status === 200) {
        setStatus(response.data.status);
        console.log(response);
        toast.success('Message Sent');
        reset;
      } else {
        toast.error('An error occured');
      }
    } catch (error) {
      console.log(error);
      toast.error('An error occured');
    }
  };

  return (
    <div className='flex flex-col justify-between pt-36 bg-gray-900 md:flex-row'>
      {/* Left side with company info */}
      <div className='w-full md:w-1/2 p-12 bg-[#e0aa22] flex flex-col gap-16'>
        <h2 className='text-xl font-semibold mb-4'>Contact Information</h2>

        <p>Adress: Abuja, Nigeria</p>
        <p>Phone: +234 8031894288</p>
        <p>Email: info@precisioninfotech.com</p>
      </div>

      {/* Right side with contact form */}
      <div className='w-full md:w-1/2 p-12 bg-white'>
        <h2 className='text-xl font-semibold mb-4'>Let's talk</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <label
              htmlFor='name'
              className='block text-sm font-medium text-gray-700'
            >
              Name
            </label>
            <input
              type='text'
              id='name'
              {...register('name')}
              className='mt-1 p-2 w-full border-[#e0aa22] border-solid border-2 rounded-md'
            />
          </div>
          <div className='mb-4'>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-700'
            >
              Email
            </label>
            <input
              type='email'
              id='email'
              {...register('email')}
              className='mt-1 p-2 w-full border-[#e0aa22] border-solid border-2 rounded-md'
            />
          </div>
          <div className='mb-4'>
            <label
              htmlFor='message'
              className='block text-sm font-medium text-gray-700'
            >
              Message
            </label>
            <textarea
              id='message'
              {...register('message')}
              rows='4'
              className='mt-1 p-2 w-full border-[#e0aa22] border-solid border-2 rounded-md'
            ></textarea>
          </div>
          <button
            type='submit'
            className='bg-[#e0aa22] text-white px-4 py-2 rounded-md hover:bg-yellow-600'
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default ContactUs;

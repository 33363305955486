import styled from 'styled-components';

export const ServicesContainer = styled.div`
  width: 100%;
  position:relative 
  background: #000;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(30, 30, 30, 1) 100%
  );

  @media screen and (max-width: 1287px) {
    height: 1900px;
  }

  @media screen and (max-width: 849px) {
    height: 2860px;
  }

  @media screen and (max-width: 400px) {
    height: 3000px;
  }
`;

export const Headertext = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  position: absolute;
  top: 750px;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media screen and (max-width: 570px) {
    top: 650px;
  }
`;
export const HeadertextLine = styled.div`
  position: relative;
  width: 30px;
  height: 1px;
  background-color: #fff;
  top: 5px;
`;
export const ServicesHeader = styled.div`
  position: absolute;
  width: 80%;
  left: 10%;
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  color: rgb(198, 170, 40);

  @media screen and (max-width: 849px) {
    font-size: 30px;
  }

  @media screen and (max-width: 570px) {
  }
`;
export const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 0.8fr));
  gap: 20px;
  width:80%
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.8fr));
  }

  @media screen and (max-width: 570px) {
  }
`;
export const Boxes = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  filter: drop-shadow(4px 5px 15px rgba(255, 83, 0, 0.1));
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4),
    0px 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0px -3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4),
    0px 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0px -3px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    border-bottom: 2px solid rgb(198, 170, 40);
  }

  @media screen and (max-width: 400px) {
    height: 330px;
  }
`;

export const Boximage = styled.div`
  position: relative;
  width: 80px;
  height: 90px;
  left: 5%;
  top: 10px;
  color: #fff;
`;
export const BoxHeader = styled.div`
  position: relative;
  width: 60%;
  left: 5%;
  top: 30px;
  font-size: 20px;
  font-weight: 700;
  color: rgb(198, 170, 40);
`;
export const Boxtext = styled.div`
  position: relative;
  width: 90%;
  left: 5%;
  top: 40px;
  font-weight: 200;
  font-size: 15px;
  line-height: 25px;

  @media screen and (max-width: 520px) {
    width: 90%;
  }
`;

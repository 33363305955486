import React, { useState } from 'react';
import './../../tailwind.css';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  userName: yup.string().required('Username is required'),
  lastName: yup.string().required('Lastname is required'),
  firstName: yup.string().required('Firstname is required'),
  password: yup.string().min(8).required('Password is required'),
  confirmPassword: yup.string().required('Passwords must match'),
  email: yup
    .string()
    .email('Enter a valid email format')
    .required('Email is required'),
});

interface FormDataTypes {
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  confirmPassword: string;
  email: string;
}

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const form = useForm<FormDataTypes>({ resolver: yupResolver(schema) });
  const { register, control, handleSubmit, formState } = form;
  const { errors, isDirty, isValid, isSubmitting } = formState;

  const onSubmit = async (data: any) => {
    console.log(data);
    setLoading(true);
    try {
      const response = await axios.post(
        'http://localhost:4000/register',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setStatus(response.data.message);
        console.log(response);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error.response.data.error);
      setLoading(false);
      setStatus(error.response.data.error);
    }
  };

  return (
    <div className='w-full h-[100vh] flex '>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='w-full h-full justify-center items-center bg-black flex '
      >
        <div className='bg-white shadow-md rounded px-8 py-6 w-1/2 mt-12'>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='firstName'
            >
              First Name
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='firstName'
              type='text'
              placeholder='Enter your First Name'
              {...register('firstName', {
                // required: {
                //   value: true,
                //   message: 'First Name is required',
                // },
              })}
            />
            <p className='text-red-400'>{errors.firstName?.message}</p>
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='lastName'
            >
              Last Name
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='lastName'
              type='text'
              placeholder='Enter your Last Name'
              {...register('lastName', {
                // required: {
                //   value: true,
                //   message: 'Last Name is required',
                // },
              })}
            />
            <p className='text-red-400'>{errors.lastName?.message}</p>
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='userName'
            >
              Username
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='userName'
              type='text'
              placeholder='Enter your username'
              {...register('userName', {
                required: {
                  value: true,
                  message: 'username is required',
                },
              })}
            />
            <p className='text-red-400'>{errors.userName?.message}</p>
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='email'
            >
              Email
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='email'
              type='email'
              placeholder='Enter your email'
              {...register('email', {
                // pattern: {
                //   value: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
                //   message: 'Enter a valid email',
                // },
                // required: {
                //   value: true,
                //   message: 'Email is required',
                // },
              })}
            />
            <p className='text-red-400'>{errors.email?.message}</p>
          </div>
          <div className='mb-6'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='password'
            >
              Password
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='password'
              placeholder='Enter your Password'
              type='password'
              {...register('password', {
                // pattern: {
                //   value:
                //     /^(?=.*[!@#$%^&*()-_=+{}\[\]|;:'",.<>?])(?=.*\d).{8,}$/,
                //   message:
                //     'Password requires 8+ characters, including 1 special character and 1 number',
                // },
                // required: {
                //   value: true,
                //   message: 'Password is required',
                // },
              })}
            />
            <p className='text-red-400'>{errors.password?.message}</p>
          </div>
          <div className='mb-6'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='confirmPassword'
            >
              Confirm Password
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='confirmPassword'
              placeholder='Confirm your Password'
              type='password'
              {...register('confirmPassword')}
            />
            <p className='text-red-400'>{errors.confirmPassword?.message}</p>
          </div>
          <div className='flex items-center justify-between'>
            <button
              // disabled={!isDirty || !isValid || isSubmitting}
              className='bg-black hover:bg-black-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='submit'
            >
              {loading ? 'Registering...' : ' Submit'}
            </button>
          </div>
          <p className='text-black'>{status}</p>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default Register;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: #000;
    background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(30, 30, 30, 1) 100%
    );
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;
    justify-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px;
`


const AboutUs = () => {
  return (
    <Container>
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-extrabold text-gray-400">Welcome to Our Website!</h1>
          <p className="mt-4 text-lg text-gray-300">
            At Precision Info Tech, We take great pride in building solutions that perform well today, and are built with an ongoing strategy of seamlessly working to meet the needs of your business.
          </p>
        </div>
        <div className="mt-10">
          <div className="max-w-7xl mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {/* Add team member cards here */}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;

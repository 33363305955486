import React from 'react'
import HeroSection from '../../components/hero-section'

import { HomeContainer } from './HomeStyle'
import Project from './ProjectSection'
import ServicesSection from './ServicesSection'

const Home = () => {
  return (
    <>

    <HomeContainer>
    <HeroSection/>
        <ServicesSection />
        <Project/>
    </HomeContainer>
    </>
    
  )
}

export default Home
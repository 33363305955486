import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  //************Mobile Responsive View*************/
  const [Mobile, setMobile] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  //***************change nav color when scrolling***************/

  const [color, setColor] = useState(false);
  const changeColor = () => {
    window.scrollY >= 75 ? setColor(true) : setColor(false);
  };

  window.addEventListener('scroll', changeColor);

  //************************************************************ */

  //Scrolls to top o page when you click on a new page link//
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMobile(false);
      }
      if (window.innerWidth < 768) {
        setMobile(true);
      }
    };

    // Call handleResize initially
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  return (
    <div className={color ? 'header-bg' : 'header'}>
      <nav className='navbar '>
        <Link to='/' className='logo'>
          <img
            src='/Logo.png'
            alt='precision_logo'
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
          />
        </Link>

        <ul
          className={
            Mobile
              ? `${mobileMenu ? 'nav-links-mobile-show' : 'nav-links-mobile'}`
              : 'nav-links'
          }
          onClick={() => setMobile(false)}
        >
          <Link to='/' className={color ? 'home-bg' : 'home'}>
            <li>Home</li>
          </Link>

          <Link to='/about' className={color ? 'about-bg' : 'about'}>
            <li>About us</li>
          </Link>

          <Link to='/services' className={color ? 'services-bg' : 'services'}>
            <li>Services</li>
          </Link>

          {/* <Link to="/blog" className='contact'>
                    <li>Blog</li>
                </Link> */}

          <Link to='/contact' className={color ? 'contact-bg' : 'contact'}>
            <li>Contact us</li>
          </Link>

          <Link to='/projects' className={color ? 'projects-bg' : 'projects'}>
            <li>Projects</li>
          </Link>
        </ul>
        {Mobile && (
          <button
            className={color ? 'mobile-menu-icon-bg' : 'mobile-menu-icon'}
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            {mobileMenu ? (
              <i className='fas fa-times' />
            ) : (
              <i className='fas fa-bars' />
            )}
          </button>
        )}
      </nav>
    </div>
  );
};

export default Navbar;

import styled  from "styled-components";


export const FooterContainer = styled.div`
    position: relative;
    width: 100%;
    height: 372px;
    background: rgba(0,0,0, .7);
    display: flex;
    justify-content: space-between;
    padding-bottom:105px;

    @media screen and (max-width: 769px) {
        height: 509px;
    }
 
    
`
export const Footerbg = styled.div`
    height: 372px ;
    position: absolute;
    width: 100%;
    z-index: -2;

    @media screen and (max-width: 769px) {
        height: 509px;
    }
`
export const FooterColumnOne = styled.div`
    position: relative;
    width: 20%;
    margin-left: 5%;
    top: 50px;
    font-weight: 400;
    font-size: 16px;
    color: #FCFCFF;
    

    @media screen and (max-width: 769px) {
        width: 60%;
        left: 5%;
        top: 22px;
    }
`
export const ColumnOneLogo = styled.div`
    position:relative;
    width: 70%;
    height: 96px;
    

    @media screen and (max-width: 769px) {
        top: 0x;
        width: 141px;
        height: 52px;
    }
`
export const ColumnOneText = styled.div`
    position: relative;
    width: 90%;
    height: 130px;
    top: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FCFCFF;

    @media screen and (max-width: 890px) {
    font-size: 12px;
}

    @media screen and (max-width: 769px) {
        top: 20px;
        width: 90%;
    }
`

export const FooterColumnTwo = styled.div`
    position: relative;
    width: 10%;
    top: 60px;

    @media screen and (max-width: 769px) {
        position: absolute;
        left: 70%;
        top: 182px;
        width: 30%;
    }
`
export const ColumnTwoHeading = styled.div`
    position: relative;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: rgb(198,170,40);

    @media screen and (max-width: 890px) {
    font-size: 12px;
    text-align: center;
}

    @media screen and (max-width: 769px) {
        
    }
`
export const ColumnTwoLinks = styled.li`
    position: relative;
    width: 100%;
    height: 24px;
    top: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 44px;
    list-style: none;
    color: #fff;

    @media screen and (max-width: 769px) {
        top: 0;
        line-height: 24px;
        text-align: center;
    }

    @media screen and (max-width: 890px) {
    font-size: 12px;
}

`

export const FooterColumnThree = styled.div`
    position: relative;
    width: 20%;
    height: 26px;
    top: 60px;
    

    @media screen and (max-width: 769px) {
        position: absolute;
        margin-top:160px;
        width: 80%;
        left: 10%;
        align-items: center;
        justify-content: center;
       
    }
`

export const ColumnThreeHeading = styled.div`
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: rgb(198,170,40);
    
    @media screen and (max-width: 890px) {
    font-size: 12px;
}
    @media screen and (max-width: 769px) {
        margin-left: 0;
    }

        @media screen and (max-width: 890px) {
    font-size: 12px;
}
`
export const ColumnThreeText = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #fff;
    margin-top: 10px;

    @media screen and (max-width: 890px) {
    font-size: 12px;
}
`




export const FooterColumnFour = styled.div`
    position: relative;
    width: 25%;
    height: 44px;
    margin-right: 5%;
    top: 60px;
    justify-content: flex-end;

    @media screen and (max-width: 769px) {
        position: absolute;
        left: 60%;
        top: 15px;
        width: 40%;
        justify-content: center;
    }
`
export const ColumnFourIcons = styled.div`
    color: white;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    font-size: 30px;
    margin-right: 10px;
   
    @media screen and (max-width: 890px) {
    font-size: 20px;
}
   

    @media screen and (max-width: 769px) {
        margin-right: 5px;
        width: 100%;
        gap: 10%;
        justify-content: center;
        align-items: center;
        align-content: center;
            
    }
`

export const ColumnFourHeading = styled.div`
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: rgb(198,170,40);
    text-align: center;

    @media screen and (max-width: 890px) {
    font-size: 12px;
}

    @media screen and (max-width: 769px) {
        
    }
`
export const FooterLine = styled.div`
    position: absolute;
    width: 90%;
    height: 0px;
    left: 5%;
    top: 319px;
    border: 1px solid #FFFFFF;

    @media screen and (max-width: 769px) {
        top: 469px;
    }

    @media screen and (max-width: 664px) {
        top: 409px;
    }
`
export const CopyrightWraper = styled.div`
    position: absolute;
    width: 80%;
    height: 24px;
    left: 10%;
    top: 329px;
    display: flex;

    @media screen and (max-width: 975px) {
        width: 90%;
        left: 5%;
    }


    @media screen and (max-width: 769px) {
        top: 479px;
        /* flex-direction: column; */
    }

    @media screen and (max-width: 664px) {
        top: 419px;
    }
    
`
export const Copyright = styled.div`
    position: relative;
    width: 60%;
    height: 24px;
    color: #fff;

    @media screen and (max-width: 890px) {
    font-size: 12px;

    @media screen and (max-width: 664px) {
        position: absolute;
        width: 100%;
        text-align: center;
        
    }
}
    

`

export const Privacy = styled.div`
    position: relative;
    width: 20%;
    color: #fff;
    text-align:center;

    @media screen and (max-width: 890px) {
    font-size: 12px;
}

@media screen and (max-width: 664px) {
        width: 50%;
        text-align: center;
        top: 30px;
    }

    @media screen and (max-width: 664px) {
        top: 40px;
    }
`

export const Terms = styled.div`
    position: relative;
    width: 20%;
    color: #fff;
    text-align:center;

    @media screen and (max-width: 890px) {
    font-size: 12px;
}

@media screen and (max-width: 664px) {
        width: 50%;
        text-align: center;
        top: 30px;
    }

    @media screen and (max-width: 664px) {
        top: 40px;
    }
`

import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
      Privacy
    </div>
  )
}

export default PrivacyPolicy

import React from 'react'
import { ColumnFourHeading, ColumnOneLogo, ColumnOneText, ColumnThreeHeading, ColumnFourIcons, ColumnTwoHeading, ColumnTwoLinks, Footerbg, FooterColumnFour, FooterColumnOne, FooterColumnThree, FooterColumnTwo, FooterContainer, ColumnThreeText, FooterLine, CopyrightWraper, Copyright, Privacy, Terms} from './FooterElements'
import {BsFacebook} from 'react-icons/bs'
import {AiFillTwitterCircle, AiFillInstagram, AiFillLinkedin} from 'react-icons/ai'
import PITLogo from '../../images/pitlogo.png'
import Footerbgimg from '../../images/footerbgg.jpg'


const Footer = () => {
  return (

    <>
    <FooterContainer>

    <Footerbg>
        <img src={Footerbgimg} alt='' style={{objectFit:'cover', height:'100%', width:'100%', top:'0'}}/>
    </Footerbg>
        <FooterColumnOne>
            <ColumnOneLogo >
            <img src={PITLogo} alt="logo"  style={{objectFit:'fill', height:'100%', width:'100%', top:'0'}}/>
            </ColumnOneLogo>
            <ColumnOneText>
            We are key player in the field of Internet solutions, System Integrations & software development 
            </ColumnOneText>
        </FooterColumnOne>

        <FooterColumnTwo>

            <ColumnTwoHeading>Quick Links</ColumnTwoHeading>
          
            <ColumnTwoLinks><a href='/about' style={{textDecoration:'none', color:'#fff'}}>About us</a></ColumnTwoLinks>
            <ColumnTwoLinks><a href='/services' style={{textDecoration:'none', color:'#fff'}}>Services</a></ColumnTwoLinks>
            <ColumnTwoLinks><a href='contact' style={{textDecoration:'none', color:'#fff'}}>Contact us</a></ColumnTwoLinks>
            <ColumnTwoLinks><a href='projects' style={{textDecoration:'none', color:'#fff'}}>Projects</a></ColumnTwoLinks>

        </FooterColumnTwo>

        <FooterColumnThree>
            <ColumnThreeHeading>Contact us</ColumnThreeHeading>
            <ColumnThreeText>+2348031894288</ColumnThreeText>
            
            <ColumnThreeText>Plot 82B Larix Homes, Apo, Abuja</ColumnThreeText>
            <ColumnThreeText>info@precisioninfotech.com</ColumnThreeText>
            
        </FooterColumnThree>

        <FooterColumnFour> 
            <ColumnFourHeading>Follow our Handles</ColumnFourHeading>
            <ColumnFourIcons>
            <a href='https://facebook.com/precisioninfotech' style={{textDecoration:'none', color:'white'}}><BsFacebook/> </a>
            <a href='https://twitter.com/precisioninfotech' style={{textDecoration:'none', color:'white'}}><AiFillTwitterCircle/> </a>
            <a href='https://www.instagram.com/precisioninfotech/' style={{textDecoration:'none', color:'white'}}><AiFillInstagram/> </a>
            <a href='https://www.linkedin.com/company/precisioninfotech' style={{textDecoration:'none', color:'white'}}><AiFillLinkedin/> </a>
            </ColumnFourIcons>
        </FooterColumnFour>
        <FooterLine/>
        <CopyrightWraper>
            <Copyright><i className="fa fa-copyright" style={{marginRight:'10px'}}></i>Copyright 2023. All Right Reserved By Precision InfoTech</Copyright>
            <Privacy><i className="fa fa-circle" style={{marginRight:'10px', color:'rgb(198,170,40)'}}></i>Privacy Policy</Privacy>
            <Terms><i className="fa fa-circle" style={{marginRight:'10px', color:'rgb(198,170,40)'}}></i>Terms of use</Terms>
        </CopyrightWraper>
    </FooterContainer>
    </>

  )
}

export default Footer